import { Injectable } from '@angular/core';
import {
  HttpContext,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@services/authentication/authentication.service';

const BYPASS_BEARER = new HttpContextToken(() => false);
export const NO_BEARER_CONTEXT = new HttpContext().set(BYPASS_BEARER, true);

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public constructor(private authenticationService: AuthenticationService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.context.get(BYPASS_BEARER) === true) {
      return next.handle(request);
    }

    const token = this.authenticationService.getToken();

    if (token) {
      if (this.authenticationService.isLoggedIn()) {
        request = this.setHeader(request, token);
      }
    }

    return next.handle(request);
  }

  private setHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return request;
  }
}
